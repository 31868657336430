<template>
  <TTView>
    <VRow>
      <VCol
        cols="12"
        md="9"
      >
        <span
          class="tt-text-headline-1 tt-light-mono-100--text"
          data-test-value="import-processing-title"
        >
          {{ $t('import.processing.title') }}
        </span>
      </VCol>
      <VCol
        cols="12"
        class="pt-1"
      >
        <ImportProcessingProgress
          :state="progressProcessingState"
          :total-percent="totalPercent"
          :progress-text="progressText"
        />
        <ImportProcessingTable
          :loading="loading"
          :items="processingData"
          @open-report-dialog="openReportDialog"
        />

        <div
          class="d-flex justify-end"
        >
          <TTBtn
            large
            class="justify-end mt-12"
            :disabled="!isFinish"
            :loading="loading"
            data-test="tt-btn"
            data-test-value="save"
            @click="handlerSubmit"
          >
            {{ $t('import.processing.finish_btn') }}
          </TTBtn>
        </div>
      </VCol>
    </VRow>

    <ErrorReportingPopup
      v-model="isShowReportDialog"
      type="processing"
      @download="downloadReport"
    />

    <template #left>
      <VRow>
        <VCol
          class="d-flex flex-column align-center"
        >
          <div
            class="d-flex flex-column align-center"
          >
            <TTBtn
              :to="{ name : Names.R_ACCOUNT_V2_COMPANY_IMPORT_INDEX }"
              fab
              elevation="1"
              color="white"
              large
              data-test="tt-btn"
              data-test-value="import-back-btn"
            >
              <VIcon>
                fal fa-arrow-left
              </VIcon>
            </TTBtn>
            <span
              class="tt-text-footnote tt-light-mono-46--text mt-2"
            >
              {{ $t('import.processing.back_btn') }}
            </span>
          </div>
        </VCol>
      </VRow>
    </template>
  </TTView>
</template>

<script>

import { TransportPolling } from '@front.backoffice/common';
import ImportProcessingTable from '../../components/import/ImportProcessingTable.vue';
import ErrorReportingPopup from '../../components/data/ErrorReportingPopup.vue';
import ImportProcessingProgress from '../../components/import/ImportProcessingProgress.vue';
import { IMPORT_FILE_STATE, IMPORT_STATE, TYPES } from '../../components/import/constants';

export default {
  name: 'ImportProcessing',

  components: {
    ErrorReportingPopup,
    ImportProcessingProgress,
    ImportProcessingTable,
  },

  inject: ['Names'],

  props: {
    importId: {
      type: String,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isShowReportDialog: false,
      importData: null,
      processingData: [],
      poll: null,
      fileTypeIcons: {
        [TYPES.teams]: 'fa-solid fa-folder-tree',
        [TYPES.staff_positions]: 'fa-solid fa-user-tie',
        [TYPES.users]: 'fa-solid fa-users',
      },
      userId: null,
      importInfo: null,
      reportImportId: null,
    };
  },

  computed: {
    filesWithErrors() {
      return this.processingData.filter(({ rowsWithErrorsCount }) => rowsWithErrorsCount > 0).length;
    },
    isFinish() {
      return this.processingState !== IMPORT_STATE.PROCESSING;
    },
    processingState() {
      return this.importData?.state || '';
    },
    progressProcessingState() {
      return this.filesWithErrors > 0 && this.processingState === IMPORT_STATE.FINISH
        ? IMPORT_STATE.ERROR
        : this.processingState;
    },
    totalPercent() {
      const files = this.processingData;
      const { done, total } = files.reduce((acc, val) => {
        const {
          finishedRowsCount,
          rowsCount,
        } = val;
        acc.done += finishedRowsCount;
        acc.total += rowsCount;
        return acc;
      }, { done: 0, total: 0 });
      return this.getPercent(done, total);
    },
    progressText() {
      if (this.processingState === IMPORT_STATE.FINISH) {
        if (this.filesWithErrors > 0) {
          return this.$tc('import.processing.pluralized.processing_error', this.filesWithErrors);
        }

        return this.$t('import.processing.success');
      }
      if (this.processingState === IMPORT_STATE.ERROR) {
        return this.$tc('import.processing.pluralized.processing_error', this.filesWithErrors);
      }
      const done = this.processingData.filter((file) => file.state === IMPORT_FILE_STATE.FINISH).length;
      const total = this.processingData.length;
      return `${this.$tc('import.processing.pluralized.processing_count.done', done)}
      ${this.$tc('import.processing.pluralized.processing_count.total', total)}`;
    },
  },

  created() {
    this.pollingData();
  },

  beforeDestroy() {
    this.stopPolling();
  },

  methods: {
    async fetch() {
      if (!this.importId) return;
      let response;
      try {
        response = await this.$di.api.FileToStream.getImport(
          { companyId: this.companyId, importId: this.importId },
        );
        this.importData = response;
        this.processingData = this.getProcessingData();
      } catch (e) {
        if ([400, 404, 500].includes(e.response?.status)) {
          this.stopPolling();
          console.error(e.response.data.error.message);
          this.$di.notify.errorHandler(e);
        }
      } finally {
        if (response?.state !== IMPORT_STATE.PROCESSING) {
          this.stopPolling();
        }
        if (response?.state === IMPORT_STATE.INITIAL) {
          await this.$router.push({
            name: this.Names.R_ACCOUNT_V2_COMPANY_IMPORT_UPLOAD,
            params: { importId: this.importId },
          });
        }
      }
    },
    async pollingData() {
      this.poll = new TransportPolling(this.fetch, {
        delay: 4000,
        retryLimit: 150,
      });
      this.poll.start();
    },
    stopPolling() {
      if (this.poll) {
        this.poll.stop();
      }
    },
    getProcessingData() {
      const rawFiles = this.importData?.files || [];
      const items = [];
      rawFiles.forEach(async (item) => {
        let { state } = item;
        if (state !== IMPORT_FILE_STATE.PROCESSING) {
          state = item.rowsWithErrorsCount > 0 ? IMPORT_FILE_STATE.ERROR : state;
        }
        const file = {
          ...item,
          badge: {
            color: this.fileBadgeColor(state),
            icon: this.fileBadgeIcon(state),
            value: [IMPORT_FILE_STATE.FINISH, IMPORT_FILE_STATE.ERROR].includes(state),
          },
          fileIcon: this.fileTypeIcons[item.type],
          fileNameClass: this.fileNameClass(state),
          fileUrl: await this.getFileTempUrl(item.id),
          reportFileUrl: await this.getReportFileUrl(item),
          processingState: {
            class: this.fileNameProcessingStateClass(state),
            text: this.fileNameProcessingStateText(item),
          },
          progress: {
            class: [IMPORT_FILE_STATE.FINISH, IMPORT_FILE_STATE.ERROR].includes(state) ? 'finish' : '',
            color: state !== IMPORT_FILE_STATE.PROCESSING ? 'tt-light-mono-4' : 'tt-light-blue',
            percent: this.getPercent(item.finishedRowsCount, item.rowsCount),
          },
        };

        items.push(file);
      });

      return items;
    },
    async getFileTempUrl(fileId) {
      if (this.processingState === IMPORT_STATE.PROCESSING) return '';
      let fileUrl = '';
      try {
        const { url } = await this.$di.api.FileToStream.getImportFileTempUrl({
          companyId: this.companyId,
          importFileId: fileId,
        });
        fileUrl = url;
      } catch (e) {
        console.warn(e);
      }
      return fileUrl;
    },
    async getReportFileUrl(file) {
      if (!file.hasReport) return '';
      let reportFileUrl = '';
      try {
        const { url } = await this.$di.api.FileToStream.getReportImportFileUrl(
          { companyId: this.companyId, importFileId: file.id },
        );
        reportFileUrl = url;
      } catch (e) {
        console.warn(e);
      }
      return reportFileUrl;
    },
    downloadReport() {
      const { reportFileUrl } = this.processingData.find((el) => el.id === this.reportImportId);
      window.open(reportFileUrl, '_blank');
      this.isShowReportDialog = false;
    },
    openReportDialog(id) {
      this.reportImportId = id;
      this.isShowReportDialog = true;
    },
    fileNameClass(state) {
      switch (state) {
        case IMPORT_FILE_STATE.FINISH:
        case IMPORT_FILE_STATE.ERROR:
          return 'tt-light-blue--text vibrant';
        default:
          return 'tt-light-mono-100--text font-weight-bold';
      }
    },
    fileNameProcessingStateClass(state) {
      switch (state) {
        case IMPORT_FILE_STATE.FINISH:
          return 'tt-light-green--text vibrant tt-text-caption';
        case IMPORT_FILE_STATE.ERROR:
          return 'tt-light-red--text vibrant tt-text-caption';
        default:
          return '';
      }
    },
    fileNameProcessingStateText(item) {
      const {
        state,
        finishedRowsCount,
        rowsWithErrorsCount,
        rowsCount,
      } = item;
      if (state === IMPORT_FILE_STATE.FINISH) {
        if (rowsWithErrorsCount > 0) {
          return this.$t('import.processing.table.file_error');
        }
        return this.$t('import.processing.table.file_finish');
      }
      if (state === IMPORT_FILE_STATE.ERROR) {
        return this.$t('import.processing.table.file_error');
      }
      const percent = `${this.getPercent(finishedRowsCount, rowsCount)}%`;
      return `${percent} (${finishedRowsCount} ${this.$t('import.processing.table.file_from')} ${rowsCount})`;
    },
    fileBadgeColor(state) {
      switch (state) {
        case IMPORT_FILE_STATE.FINISH:
          return 'tt-light-green vibrant';
        case IMPORT_FILE_STATE.ERROR:
          return 'tt-light-red vibrant';
        default:
          return 'tt-light-green vibrant';
      }
    },
    fileBadgeIcon(state) {
      switch (state) {
        case IMPORT_FILE_STATE.FINISH:
          return 'fas fa-check';
        case IMPORT_FILE_STATE.ERROR:
          return 'fas fa-exclamation';
        default:
          return 'fas fa-check';
      }
    },
    getPercent(done, total) {
      return Math.floor((done * 100) / total) || 0;
    },
    handlerSubmit() {
      this.$router.push({ name: this.Names.R_ACCOUNT_V2_COMPANY_IMPORT_INDEX });
    },
  },
};
</script>
