<template>
  <div
    class="progress-status d-flex align-center pa-2 pr-4 mb-8"
  >
    <VImg
      v-if="state === IMPORT_STATE.FINISH"
      :src="require('@/assets/import-processing-finish.png')"
      max-height="80"
      max-width="80"
      contain
    />
    <VImg
      v-else-if="state === IMPORT_STATE.ERROR"
      :src="require('@/assets/import-processing-error.png')"
      max-height="80"
      max-width="80"
      contain
    />
    <VImg
      v-else
      :src="require('@/assets/import-processing-processing.png')"
      max-height="80"
      max-width="80"
      contain
    />
    <div
      class="flex-grow-1 ml-3"
    >
      <div class="d-flex justify-space-between tt-text-title-2 mb-2">
        <span>{{ title }}</span>
        <span>{{ percent }}%</span>
      </div>
      <VProgressLinear
        :height="4"
        :value="percent"
        background-color="tt-light-mono-4"
        color="tt-light-blue vibrant"
        stream
      />
      <div
        class="tt-text-body-2 mt-2"
      >
        <VIcon
          v-if="state === IMPORT_STATE.ERROR"
          size="20"
          color="tt-light-red"
        >
          fas fa-exclamation-circle
        </VIcon>
        {{ progressText }}
      </div>
    </div>
  </div>
</template>

<script>
import { IMPORT_STATE } from './constants';

export default {
  name: 'ImportProcessingProgress',
  props: {
    state: {
      type: String,
      default: '',
    },
    progressText: {
      type: String,
      default: '',
    },
    totalPercent: {
      type: [Number, String],
      default: '0',
    },
  },
  data() {
    return {
      disabled: false,
      IMPORT_STATE,
    };
  },
  computed: {
    percent() {
      if (this.state === IMPORT_STATE.PROCESSING) {
        return this.totalPercent;
      }
      if ([IMPORT_STATE.ERROR, IMPORT_STATE.FINISH].includes(this.state)) {
        return 100;
      }
      return 0;
    },
    title() {
      switch (this.state) {
        case IMPORT_STATE.PROCESSING:
          return this.$t('import.processing.progress.processing');
        case IMPORT_STATE.FINISH:
          return this.$t('import.processing.progress.finish');
        case IMPORT_STATE.ERROR:
          return this.$t('import.processing.progress.error');
        default:
          return this.$t('import.processing.progress.processing');
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.progress-status {
  border: 1px solid #EBECED;
  border-radius: 8px;
}
</style>
