<template>
  <TTDataTable
    class="mt-4"
    :headers="headers"
    :items="items"
    :loading="loading"
    disable-pagination
    hide-default-footer
    data-test="tt-table"
    data-test-value="import-progress-table"
    extra-tall
  >
    <template #item.fileName="{item}">
      <div
        class="d-flex"
      >
        <div
          :class="item.progress.class"
          class="tt-progress-circular mr-3"
        >
          <VBadge
            :color="item.badge.color"
            :icon="item.badge.icon"
            :value="item.badge.value"
            bordered
            class="custom-badge"
            offset-x="18"
            offset-y="16"
            overlap
          >
            <VProgressCircular
              :rotate="-90"
              :size="44"
              :width="2"
              :value="item.progress.percent"
              :color="item.progress.color"
            />
            <VIcon
              size="15"
              class="progress-icon"
              color="tt-light-blue"
            >
              {{ item.fileIcon }}
            </VIcon>
          </VBadge>
        </div>
        <div>
          <a
            v-if="item.fileUrl"
            :class="item.fileNameClass"
            class="text-decoration-none"
            data-test-value="import-file-name"
            :href="item.fileUrl"
          >
            {{ item.fileName || item.filename }}
          </a>
          <div
            v-else
            :class="item.fileNameClass"
            data-test-value="import-file-name"
          >
            {{ item.fileName || item.filename }}
          </div>
          <div
            :class="item.processingState.class"
            data-test-value="import-progress-text"
          >
            {{ item.processingState.text }}
          </div>
        </div>
      </div>
    </template>
    <template #item.rowsWithErrorsCount="{item}">
      <div
        class="d-flex justify-space-between align-center"
      >
        <span
          :class="item.rowsWithErrorsCount > 0 ? 'tt-light-red--text' : ''"
        >
          {{ item.rowsWithErrorsCount }}
        </span>
        <TTBtn
          v-if="item.hasReport && reportsFeatureIsActive"
          class="tt-text-button-2 tt-secondary"
          data-test="tt-btn"
          :data-test-label="testLabelType(item.type)"
          data-test-value="report"
          height="36"
          @click="openReportDialog(item.id)"
        >
          {{ $t('import.processing.table.error_report') }}
        </TTBtn>
      </div>
    </template>
  </TTDataTable>
</template>

<script>
export default {
  name: 'ImportProcessingTable',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      disabled: false,

      headers: [
        {
          text: this.$t('import.processing.table.headers.file_name'),
          value: 'fileName',
          sortable: false,
        },
        {
          text: this.$t('import.processing.table.headers.rows_count'),
          value: 'rowsCount',
          width: 150,
          sortable: false,
        },
        {
          text: this.$t('import.processing.table.headers.finished_rows_count'),
          value: 'finishedRowsCount',
          width: 150,
          sortable: false,
        },
        {
          text: this.$t('import.processing.table.headers.rows_with_errors_count'),
          value: 'rowsWithErrorsCount',
          width: 270,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    reportsFeatureIsActive() {
      return this.$di.ff.get('import_reports');
    },
  },
  methods: {
    testLabelType(type) {
      switch (type) {
        case 'teams':
          return 'team';
        case 'staff_positions':
          return 'sp';
        default:
          return 'user';
      }
    },
    openReportDialog(id) {
      this.$emit('open-report-dialog', id);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {

  .custom-badge {
    z-index: 3;
    padding: 0;
    line-height: 1.5;

    .v-icon {
      font-size: 10px;
    }
  }

  table tbody tr td {
    border-bottom: 1px solid map-get($tt-light-mono-8, 'base') !important;
    background-color: map-deep-get($tt-light-mono-0, 'base') !important;

    &:not(:last-child) {
      border-right: none !important;
    }
  }
}

.tt-progress-circular {
  position: relative;
  width: 44px;
  height: 44px;
  background-color: map-get($tt-light-mono-0, 'base');
  border-radius: 50%;

  .progress-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:before {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    background-color: map-get($tt-light-mono-4, 'base');
    border-radius: 50%;
    content: '';
  }

  &.finish {
    &:before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
